.popup {
  width: 100%;
  display: flow-root;
  position: relative;
  flex-direction: row;
}
.popup__text {
  color: var(--dl-color-feelers-dark);
  margin-right: var(--dl-space-space-halfunit);
}
.popup__link {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  text-decoration: none;
}
.popup__link:hover {
  color: var(--dl-color-feelers-accent);
}

@media (max-width: 767px) {
  .popup {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
