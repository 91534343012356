.work-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.work-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.work-image {
  top: -15%;
  left: 20%;
  width: 300px;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.work-image1 {
  top: 40%;
  left: -10%;
  width: 350px;
  position: absolute;
  object-fit: cover;
}
.work-image2 {
  right: 20%;
  width: 400px;
  bottom: 5%;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.work-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.work-title-container {
  width: 100%;
  display: flex;
  z-index: 2;
  position: fixed;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
}
.work-rnd {
  align-items: flex-start;
}
.work-projects {
  align-items: flex-start;
}
.work-products {
  align-items: flex-start;
}
.work-education {
  align-items: flex-start;
}
.work-outreach {
  align-items: flex-start;
}
.work-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.work-row1-container {
  display: flex;
  flex-direction: row;
}
.work-talktouch {
  width: 90%;
  margin-top: 550px;
}
.work-rotary-phone {
  width: 90%;
  margin-top: 400px;
}
.work-col345-container {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.work-row1-container1 {
  display: flex;
  flex-direction: row;
}
.work-col34-container {
  width: 66.66%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.work-col5-container {
  width: 33.33%;
}
.work-writing-machine {
  width: 85%;
  margin-top: var(--dl-space-space-fiveunits);
  text-decoration: none;
}
.work-row2-container {
  display: flex;
  flex-direction: row;
}
.work-col34-container1 {
  width: 66.66%;
}
.work-writing-machine1 {
  width: 85%;
  margin-top: var(--dl-space-space-fiveunits);
  text-decoration: none;
}
.work-col5-container1 {
  width: 33.33%;
}
.work-row3-container {
  display: flex;
  margin-top: -140px;
  flex-direction: row;
}
.work-col3-container {
  width: 33.33%;
}
.work-col4-container {
  width: 33.33%;
}
.work-kinetic-birds {
  margin-top: 150px;
}
.work-col5-container2 {
  width: 33.33%;
}
.work-generative-dreaming {
  width: 95%;
  margin-top: var(--dl-space-space-doubleunit);
}
.work-good-am {
  width: 85%;
}
.work-navlink07 {
  display: contents;
}
.work-greener-screens {
  align-self: center;
}
.work-row2-container1 {
  display: flex;
  flex-direction: row;
}
.work-labs {
  margin-top: 500px;
}
.work-streetlamp {
  margin-top: 200px;
}
.work-caifan {
  width: 85%;
}
.work-caifan:hover {
  color: var(--dl-color-feelers-accent);
  width: 90%;
}
.work-caterfeelers {
  margin-top: 80px;
}
.work-col45-container {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.work-row1-container2 {
  display: flex;
  flex-direction: row;
}
.work-col4-container1 {
  width: 50%;
}
.work-col5-container3 {
  width: 50%;
}
.work-navlink13 {
  display: contents;
}
.work-youtube {
  width: 85%;
  align-self: center;
  margin-top: 100px;
}
.work-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 1200px) {
  .work-rnd {
    font-size: medium;
  }
  .work-projects {
    font-size: medium;
  }
  .work-products {
    font-size: medium;
  }
  .work-education {
    font-size: medium;
  }
  .work-outreach {
    font-size: medium;
  }
}
@media(max-width: 991px) {
  .work-title-container {
    display: none;
  }
  .work-row1-container {
    flex-direction: column-reverse;
  }
  .work-col1-container {
    width: 100%;
  }
  .work-talktouch {
    margin-top: 0;
  }
  .work-col2-container {
    width: 100%;
  }
  .work-rotary-phone {
    margin: 0;
  }
  .work-col345-container {
    width: 100%;
  }
  .work-row1-container1 {
    flex-direction: column;
  }
  .work-col34-container {
    width: 100%;
  }
  .work-col5-container {
    width: 100%;
  }
  .work-writing-machine {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .work-row2-container {
    flex-direction: column;
  }
  .work-col34-container1 {
    width: 100%;
  }
  .work-writing-machine1 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .work-col5-container1 {
    width: 100%;
  }
  .work-row3-container {
    flex-direction: column;
  }
  .work-col3-container {
    width: 100%;
  }
  .work-col4-container {
    width: 100%;
  }
  .work-kinetic-birds {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .work-col5-container2 {
    width: 100%;
  }
  .work-row2-container1 {
    flex-direction: column-reverse;
  }
  .work-col1-container1 {
    width: 100%;
  }
  .work-labs {
    margin-top: 0;
  }
  .work-col2-container1 {
    width: 100%;
  }
  .work-streetlamp {
    margin-top: 0;
  }
  .work-col3-container1 {
    width: 100%;
  }
  .work-caterfeelers {
    margin-top: 0;
  }
  .work-col45-container {
    width: 100%;
  }
  .work-row1-container2 {
    flex-direction: column;
  }
  .work-col4-container1 {
    width: 100%;
  }
  .work-col5-container3 {
    width: 100%;
  }
  .work-youtube {
    margin-top: 0;
  }
}
@media(max-width: 767px) {
  .work-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
