.archive-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.archive-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.archive-image {
  top: 12%;
  left: 10%;
  width: 320px;
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.archive-image1 {
  top: -22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.archive-image2 {
  left: -13%;
  width: 400px;
  bottom: 5%;
  position: absolute;
  object-fit: cover;
}
.archive-hori-line {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 40%;
  display: flex;
  z-index: 1;
  position: absolute;
  align-self: flex-start;
  border-color: var(--dl-color-feelers-light);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.archive-vert-line {
  top: 0px;
  right: 0px;
  width: 50%;
  height: 100%;
  display: flex;
  z-index: 1;
  position: absolute;
  align-self: flex-start;
  border-color: var(--dl-color-feelers-light);
  border-style: solid;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.archive-navbar-container {
  width: 100%;
  display: flex;
  z-index: 4;
  position: fixed;
  flex-direction: column;
}
.archive-body-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.archive-graph-container {
  width: 100%;
  height: 60vh;
  z-index: 3;
  position: relative;
}
.archive-greener-screens {
  top: 1%;
  left: 7%;
  position: absolute;
}
.archive-labs {
  top: 13%;
  left: 13%;
  position: absolute;
}
.archive-caterfeelers {
  top: 24%;
  left: 36%;
  position: absolute;
}
.archive-rotary-phone {
  top: 48%;
  left: 20%;
  position: absolute;
}
.archive-youtube {
  top: 10%;
  right: 46%;
  position: absolute;
}
.archive-streetlamp {
  top: 18%;
  right: 10%;
  position: absolute;
}
.archive-kinetic-birds {
  top: 40%;
  right: 20%;
  position: absolute;
}
.archive-talktouch {
  right: 40%;
  bottom: 35%;
  position: absolute;
}
.archive-generative-dreaming {
  right: 15%;
  bottom: 5%;
  position: absolute;
}
.archive-caifan {
  right: 46%;
  bottom: 15%;
  position: absolute;
}
.archive-good-am {
  left: 2%;
  bottom: 40%;
  position: absolute;
}
.archive-another-world {
  left: 10%;
  bottom: 20%;
  position: absolute;
}
.archive-tagline-container {
  display: flex;
  z-index: 2;
  position: relative;
}
.archive-heading {
  color: var(--dl-color-feelers-dark);
  align-self: center;
  font-style: normal;
}
.archive-heading1 {
  color: var(--dl-color-feelers-dark);
  align-self: center;
  font-style: normal;
}
.archive-heading2 {
  color: var(--dl-color-feelers-light);
  align-self: center;
  font-style: normal;
}
.archive-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 4;
  position: relative;
}
@media(max-width: 991px) {
  .archive-hori-line {
    width: 100%;
  }
  .archive-vert-line {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .archive-tagline-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .archive-hori-line {
    width: 100%;
  }
  .archive-vert-line {
    width: 50%;
  }
  .archive-tagline-container {
    flex-direction: column;
  }
}
