.build-a-caifan-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.build-a-caifan-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.build-a-caifan-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.build-a-caifan-image1 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.build-a-caifan-image2 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.build-a-caifan-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.build-a-caifan-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.build-a-caifan-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.build-a-caifan-title {
  color: var(--dl-color-feelers-dark);
}
.build-a-caifan-img-container {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  flex-direction: column;
}
.build-a-caifan-col2 {
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.build-a-caifan-image3 {
  width: 47%;
  object-fit: contain;
}
.build-a-caifan-image4 {
  width: 47%;
  object-fit: contain;
}
.build-a-caifan-image5 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.build-a-caifan-image6 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.build-a-caifan-right-container {
  width: 60%;
  display: flex;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.build-a-caifan-header-container {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.build-a-caifan-text {
  color: var(--dl-color-feelers-dark);
}
.build-a-caifan-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.build-a-caifan-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.build-a-caifan-left-container1 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.build-a-caifan-text1 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
}
.build-a-caifan-row2-container {
  display: flex;
  justify-content: space-between;
}
.build-a-caifan-link {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  height: fit-content;
  font-style: italic;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-fiveunits);
  text-decoration: none;
}
.build-a-caifan-link:hover {
  color: var(--dl-color-feelers-accent);
}
.build-a-caifan-col2-container {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.build-a-caifan-text2 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-fourunits);
}
.build-a-caifan-link1 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  text-decoration: none;
}
.build-a-caifan-link1:hover {
  color: var(--dl-color-feelers-accent);
}
.build-a-caifan-text3 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tenunits);
}
.build-a-caifan-iframe-container {
  position: relative;
  padding-bottom: 56.25%;
}
.build-a-caifan-container1 {
  width: 100%;
  height: 100%;
  position: absolute;
}
.build-a-caifan-container2 {
  display: contents;
}
.build-a-caifan-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .build-a-caifan-body-container {
    flex-direction: column;
  }
  .build-a-caifan-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .build-a-caifan-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .build-a-caifan-row1-container {
    flex-direction: column;
  }
  .build-a-caifan-text1 {
    width: 100%;
  }
  .build-a-caifan-row2-container {
    flex-direction: column;
  }
  .build-a-caifan-link {
    width: 100%;
  }
  .build-a-caifan-col2-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .build-a-caifan-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .build-a-caifan-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
