.tb05-credits-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tb05-credits-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.tb05-credits-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.tb05-credits-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  margin-top: 100px;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.tb05-credits-r1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tb05-credits-t1 {
  color: var(--dl-color-touchbase-lblue);
}
.tb05-credits-i0 {
  width: 30px;
  object-fit: cover;
}
.tb05-credits-r2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.tb05-credits-profile-slot {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-profile-slot1 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-profile-slot2 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-profile-slot3 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-r3 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.tb05-credits-profile-slot4 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-profile-slot5 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-profile-slot6 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-profile-slot7 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.tb05-credits-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media (max-width: 991px) {
  .tb05-credits-body-container {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .tb05-credits-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .tb05-credits-r2 {
    margin-top: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .tb05-credits-profile-slot {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-r3 {
    margin-top: 0px;
    margin-bottom: 0px;
    flex-direction: column;
  }
  .tb05-credits-profile-slot4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot6 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot7 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 479px) {
  .tb05-credits-r2 {
    font-size: 45px;
  }
  .tb05-credits-profile-slot {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot6 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-profile-slot7 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .tb05-credits-t1 {
    font-size: 40px;
  }
}
