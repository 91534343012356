.soai-main-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.soai-main-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.soai-main-body {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.soai-main-r1 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.soai-main-r1-c1 {
  width: 15%;
  display: flex;
  flex-direction: column;
}
.soai-main-r1-c2 {
  width: 65%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.soai-main-t1 {
  width: 80%;
  text-align: center;
}
.soai-main-r1-c3 {
  width: 12%;
  display: flex;
  flex-direction: column;
}
.soai-main-i2 {
  margin-top: var(--dl-space-space-tenunits);
  object-fit: contain;
}
.soai-main-r2 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
}
.soai-main-navlink {
  display: contents;
}
.soai-main-r2-c1 {
  width: 50%;
  display: flex;
  position: relative;
  transition: 0.3s;
  margin-left: var(--dl-space-space-tripleunit);
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.soai-main-r2-c1:hover {
  color: var(--dl-color-feelers-accent);
  width: 100%;
}
.soai-main-t2 {
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-main-i3 {
  width: 70%;
  align-self: center;
  object-fit: contain;
}
.soai-main-navlink1 {
  display: contents;
}
.soai-main-r2-c2 {
  width: 50%;
  display: flex;
  transition: 0.3s;
  margin-left: var(--dl-space-space-tripleunit);
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  text-decoration: none;
}
.soai-main-r2-c2:hover {
  color: var(--dl-color-feelers-accent);
  width: 100%;
}
.soai-main-t3 {
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-main-text11 {
  font-family: "Rubik";
  font-weight: 400;
}
.soai-main-i4 {
  width: 70%;
  align-self: center;
  object-fit: contain;
}
.soai-main-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media (max-width: 1200px) {
  .soai-main-text {
    text-align: center;
  }
  .soai-main-text02 {
    text-align: center;
  }
}
@media (max-width: 991px) {
  .soai-main-r2 {
    align-items: center;
    flex-direction: column-reverse;
  }
  .soai-main-r2-c1 {
    width: 100%;
  }
  .soai-main-r2-c2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 767px) {
  .soai-main-r1 {
    align-items: center;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-main-r1-c2 {
    width: 100%;
    padding-top: var(--dl-space-space-doubleunit);
  }
  .soai-main-i2 {
    margin-top: 0;
  }
  .soai-main-r2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .soai-main-i3 {
    width: 100%;
  }
  .soai-main-i4 {
    width: 100%;
  }
}
