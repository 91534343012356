.kinetic-birds-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.kinetic-birds-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.kinetic-birds-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.kinetic-birds-image01 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.kinetic-birds-image02 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.kinetic-birds-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.kinetic-birds-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.kinetic-birds-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.kinetic-birds-title {
  color: var(--dl-color-feelers-dark);
}
.kinetic-birds-img-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.kinetic-birds-image03 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.kinetic-birds-image04 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.kinetic-birds-image05 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.kinetic-birds-col2 {
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.kinetic-birds-image06 {
  width: 47%;
  object-fit: contain;
}
.kinetic-birds-image07 {
  width: 47%;
  object-fit: contain;
}
.kinetic-birds-image08 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.kinetic-birds-col21 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.kinetic-birds-image09 {
  width: 47%;
  object-fit: contain;
}
.kinetic-birds-image10 {
  width: 47%;
  object-fit: contain;
}
.kinetic-birds-right-container {
  width: 60%;
  display: flex;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.kinetic-birds-header-container {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.kinetic-birds-text {
  color: var(--dl-color-feelers-dark);
}
.kinetic-birds-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.kinetic-birds-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.kinetic-birds-left-container1 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.kinetic-birds-text01 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
}
.kinetic-birds-row2-container {
  display: flex;
  margin-bottom: 130px;
  justify-content: space-between;
}
.kinetic-birds-text10 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  font-style: italic;
  padding-top: var(--dl-space-space-halfunit);
}
.kinetic-birds-col2-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.kinetic-birds-text15 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.kinetic-birds-link {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
}
.kinetic-birds-link:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-row3-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.kinetic-birds-text23 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  text-align: right;
  font-weight: bold;
}
.kinetic-birds-text27 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
  font-style: italic;
}
.kinetic-birds-row4-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.kinetic-birds-text30 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  text-align: right;
  font-weight: bold;
}
.kinetic-birds-text34 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
  font-style: italic;
}
.kinetic-birds-row5-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.kinetic-birds-text35 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  text-align: right;
  font-weight: bold;
}
.kinetic-birds-text36 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
  font-style: italic;
}
.kinetic-birds-row6-container {
  display: flex;
  justify-content: space-between;
}
.kinetic-birds-left-container2 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.kinetic-birds-right-container1 {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.kinetic-birds-link1 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: line-through;
}
.kinetic-birds-link1:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-text39 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-fourunits);
}
.kinetic-birds-text40 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.kinetic-birds-link2 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  text-decoration: none;
}
.kinetic-birds-link2:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-text41 {
  color: var(--dl-color-feelers-dark);
  font-size: 16px;
  transition: 0.3s;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
}
.kinetic-birds-text41:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-link3 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  text-decoration: none;
}
.kinetic-birds-link3:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-text42 {
  color: var(--dl-color-feelers-dark);
  font-size: 16px;
  transition: 0.3s;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
}
.kinetic-birds-text42:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-link4 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  text-decoration: none;
}
.kinetic-birds-link4:hover {
  color: var(--dl-color-feelers-accent);
}
.kinetic-birds-text43 {
  color: var(--dl-color-feelers-dark);
}
.kinetic-birds-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .kinetic-birds-body-container {
    flex-direction: column;
  }
  .kinetic-birds-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .kinetic-birds-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .kinetic-birds-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .kinetic-birds-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .kinetic-birds-row1-container {
    flex-direction: column;
  }
  .kinetic-birds-text01 {
    width: 100%;
  }
  .kinetic-birds-row2-container {
    flex-direction: column;
  }
  .kinetic-birds-text10 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .kinetic-birds-col2-container {
    width: 100%;
  }
  .kinetic-birds-row6-container {
    flex-direction: column;
  }
  .kinetic-birds-right-container1 {
    width: 100%;
  }
}
