.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.footer-copyright-container {
  width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.footer-link {
  color: var(--dl-color-feelers-dark);
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.footer-link1 {
  color: var(--dl-color-feelers-dark);
  align-self: flex-start;
  transition: 0.3s;
}
.footer-link1:hover {
  color: var(--dl-color-feelers-accent);
}
.footer-social-links {
  gap: var(--dl-space-space-doubleunit);
  display: flex;
  flex-direction: row;
}
.footer-link2 {
  display: contents;
}
.footer-icon {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
}
.footer-icon:hover {
  fill: var(--dl-color-feelers-accent);
}
.footer-link3 {
  display: contents;
}
.footer-icon2 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
}
.footer-icon2:hover {
  fill: var(--dl-color-feelers-accent);
}
.footer-link4 {
  display: contents;
}
.footer-icon4 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
}
.footer-icon4:hover {
  fill: var(--dl-color-feelers-accent);
}
.footer-root-class-name {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name1 {
  height: 100%;
}
.footer-root-class-name2 {
  height: 100%;
}
.footer-root-class-name3 {
  height: 100%;
}
.footer-root-class-name4 {
  height: 100%;
  max-width: auto;
}
.footer-root-class-name5 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name6 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name7 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name8 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name9 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name10 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name11 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name12 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name13 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name14 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name15 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name16 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name17 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name18 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name19 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name20 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name21 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name22 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name23 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name24 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name25 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name26 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name27 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name28 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name29 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name30 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name31 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name32 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name33 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name34 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name35 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name36 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name37 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.footer-root-class-name38 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
@media(max-width: 767px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-copyright-container {
    width: 100%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .footer-copyright-container {
    width: 100%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
