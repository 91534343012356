.item-item {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.item-item-img {
  object-fit: contain;
}
.item-item-title {
  color: inherit;
}
.item-root-class-name {
  width: auto;
}
.item-root-class-name1 {
  width: auto;
}
.item-root-class-name2 {
  width: auto;
}
