.tb04-editorial-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tb04-editorial-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.tb04-editorial-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.tb04-editorial-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  margin-top: 100px;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.tb04-editorial-r1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tb04-editorial-t1 {
  color: var(--dl-color-touchbase-lblue);
}
.tb04-editorial-i0 {
  width: 30px;
  object-fit: cover;
}
.tb04-editorial-r2 {
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: space-between;
}
.tb04-editorial-r2-r1 {
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tb04-editorial-t2 {
  color: var(--dl-color-touchbase-dblue);
  font-size: 60px;
  font-family: "Frank";
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: none;
  text-decoration: none;
}
.tb04-editorial-t3 {
  color: var(--dl-color-touchbase-lgreen);
}
.tb04-editorial-t4 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-tenunits);
  margin-left: var(--dl-space-space-tenunits);
}
.tb04-editorial-r2-r2 {
  width: 40%;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.tb04-editorial-i1 {
  width: 100%;
  object-fit: cover;
}
.tb04-editorial-t5 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r3 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.tb04-editorial-r3-c1 {
  flex: 0 0 auto;
  width: 55%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tb04-editorial-t7 {
  width: 70%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-tripleunit);
}
.tb04-editorial-t401 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-tenunits);
}
.tb04-editorial-r3-c2 {
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tb04-editorial-i101 {
  width: 100%;
  margin-top: 200px;
  object-fit: cover;
}
.tb04-editorial-t501 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r4 {
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.tb04-editorial-r4-c1 {
  flex: 0 0 auto;
  width: 55%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tb04-editorial-t402 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-r4-c2 {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tb04-editorial-i102 {
  width: 100%;
  margin-top: 360px;
  object-fit: cover;
}
.tb04-editorial-t502 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r5 {
  width: 60%;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.tb04-editorial-i103 {
  width: 100%;
  margin-top: var(--dl-space-space-fiveunits);
  object-fit: cover;
}
.tb04-editorial-t503 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r6 {
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-fiveunits);
  justify-content: flex-end;
}
.tb04-editorial-r3-c11 {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.tb04-editorial-t61 {
  color: var(--dl-color-touchbase-dblue);
  letter-spacing: 1;
}
.tb04-editorial-t71 {
  color: var(--dl-color-touchbase-dblue);
  width: 70%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-tripleunit);
}
.tb04-editorial-t403 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-tenunits);
}
.tb04-editorial-i104 {
  width: 100%;
  margin-top: var(--dl-space-space-fiveunits);
  object-fit: cover;
}
.tb04-editorial-t504 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-text28 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-fiveunits);
}
.tb04-editorial-r7 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: 200px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.tb04-editorial-r3-c12 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.tb04-editorial-t62 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-t72 {
  color: var(--dl-color-touchbase-dblue);
  width: 70%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-tripleunit);
}
.tb04-editorial-t404 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-tenunits);
}
.tb04-editorial-r3-c21 {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.tb04-editorial-i105 {
  width: 100%;
  margin-top: 240px;
  object-fit: cover;
}
.tb04-editorial-t505 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r8 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: 200px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: space-between;
}
.tb04-editorial-r3-c13 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.tb04-editorial-t405 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-r3-c22 {
  width: 70%;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.tb04-editorial-i106 {
  width: 100%;
  object-fit: cover;
}
.tb04-editorial-t506 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r9 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: 200px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: space-between;
}
.tb04-editorial-r3-c14 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.tb04-editorial-t406 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-r3-c23 {
  width: 60%;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.tb04-editorial-i107 {
  width: 100%;
  object-fit: cover;
}
.tb04-editorial-t507 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r10 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: 200px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.tb04-editorial-r3-c15 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.tb04-editorial-t407 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-r3-c24 {
  width: 40%;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.tb04-editorial-i108 {
  width: 100%;
  object-fit: cover;
}
.tb04-editorial-t508 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r11 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);

  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: space-between;
}
.tb04-editorial-r3-c25 {
  width: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.tb04-editorial-i109 {
  object-fit: cover;
}
.tb04-editorial-t509 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-unit);
}
.tb04-editorial-r3-c16 {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.tb04-editorial-t408 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-r12 {
  display: flex;
  align-items: flex-start;
  padding-top: 150px;
  padding-left: 400px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: space-between;
}
.tb04-editorial-t409 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-r11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text36 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-link {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.tb04-editorial-r21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text37 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-link1 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r31 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text38 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-link2 {
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r41 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text39 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-text40 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r51 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text41 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-text42 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r61 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text43 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-link3 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r71 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text44 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-link4 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r81 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text47 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-text48 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-r91 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tb04-editorial-text49 {
  color: var(--dl-color-touchbase-dblue);
}
.tb04-editorial-text50 {
  color: var(--dl-color-touchbase-dblue);
  margin-left: var(--dl-space-space-doubleunit);
}
.tb04-editorial-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
.links-colours {
  color: var(--dl-color-touchbase-lgreen);
}
@media (max-width: 991px) {
  .tb04-editorial-body-container {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .tb04-editorial-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .tb04-editorial-r2 {
    margin-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: 0px;
  }
  .tb04-editorial-r2-r1 {
    width: 100%;
  }
  .tb04-editorial-t4 {
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
  }
  .tb04-editorial-r2-r2 {
    width: 100%;
    margin-top: var(--dl-space-space-fourunits);
    margin-right: 0px;
  }
  .tb04-editorial-r3 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .tb04-editorial-r3-c1 {
    width: 100%;
  }
  .tb04-editorial-t7 {
    width: 100%;
    margin-top: var(--dl-space-space-doubleunit);
  }
  .tb04-editorial-t401 {
    margin-top: var(--dl-space-space-fourunits);
  }
  .tb04-editorial-r3-c2 {
    width: 100%;
  }
  .tb04-editorial-i101 {
    margin-top: var(--dl-space-space-fourunits);
  }
  .tb04-editorial-r4 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .tb04-editorial-r4-c1 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r4-c2 {
    width: 100%;
  }
  .tb04-editorial-i102 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r5 {
    width: 100%;
  }
  .tb04-editorial-r6 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: 0px;
  }
  .tb04-editorial-r3-c11 {
    width: 100%;
  }
  .tb04-editorial-t71 {
    width: 100%;
    margin-top: var(--dl-space-space-fourunits);
  }
  .tb04-editorial-t403 {
    margin-top: var(--dl-space-space-fourunits);
  }
  .tb04-editorial-r7 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .tb04-editorial-r3-c12 {
    width: 100%;
  }
  .tb04-editorial-t72 {
    width: 100%;
    margin-top: var(--dl-space-space-fourunits);
  }
  .tb04-editorial-t404 {
    margin-top: var(--dl-space-space-fourunits);
  }
  .tb04-editorial-r3-c21 {
    width: 100%;
  }
  .tb04-editorial-i105 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r8 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: 0px;
  }
  .tb04-editorial-r3-c13 {
    width: 100%;
  }
  .tb04-editorial-r3-c22 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r9 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: var(--dl-space-space-halfunit);
  }
  .tb04-editorial-r3-c14 {
    width: 100%;
  }
  .tb04-editorial-r3-c23 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r10 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .tb04-editorial-r3-c15 {
    width: 100%;
  }
  .tb04-editorial-r3-c24 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r11 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 0px;
    padding-right: 0px;
  }
  .tb04-editorial-r3-c25 {
    width: 100%;
  }
  .tb04-editorial-r3-c16 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb04-editorial-r12 {
    padding-top: var(--dl-space-space-tenunits);
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 479px) {
  .tb04-editorial-t1 {
    font-size: 45px;
  }
  .tb04-editorial-t2 {
    font-size: 50px;
  }
}
