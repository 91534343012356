.tb03-programs-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tb03-programs-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.tb03-programs-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.tb03-programs-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  margin-top: 100px;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.tb03-programs-r1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tb03-programs-t1 {
  color: var(--dl-color-touchbase-lblue);
}
.tb03-programs-i1 {
  width: 30px;
  object-fit: cover;
}
.tb03-programs-navlink {
  display: contents;
}
.tb03-programs-r2 {
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
}
.tb03-programs-navlink1 {
  display: contents;
}
.tb03-programs-r3 {
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 380px;
  text-decoration: none;
}
.tb03-programs-navlink2 {
  display: contents;
}
.tb03-programs-r4 {
  width: 25%;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  margin-right: 200px;
  text-decoration: none;
}
.tb03-programs-navlink3 {
  display: contents;
}
.tb03-programs-r5 {
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 450px;
  text-decoration: none;
}
.tb03-programs-navlink4 {
  display: contents;
}
.tb03-programs-r6 {
  width: 25%;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  text-decoration: none;
}
.tb03-programs-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media (max-width: 991px) {
  .tb03-programs-body-container {
    flex-direction: column;
  }
  .tb03-programs-r2 {
    width: 40%;
    align-self: center;
    margin-top: var(--dl-space-space-fiveunits);
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .tb03-programs-r3 {
    width: 40%;
    align-self: center;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .tb03-programs-r4 {
    width: 40%;
    align-self: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .tb03-programs-r5 {
    width: 40%;
    align-self: center;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .tb03-programs-r6 {
    width: 40%;
    align-self: center;
  }
}
@media (max-width: 767px) {
  .tb03-programs-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .tb03-programs-r2 {
    width: 100%;
  }
  .tb03-programs-r3 {
    width: 100%;
  }
  .tb03-programs-r4 {
    width: 100%;
  }
  .tb03-programs-r5 {
    width: 100%;
  }
  .tb03-programs-r6 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .tb03-programs-t1 {
    font-size: 45px;
  }
}
