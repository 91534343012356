.soai-july-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.soai-july-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.soai-july-body {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.soai-july-r1 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.soai-july-r1-r1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.soai-july-r1-r1-c1 {
  width: 20%;
  display: flex;
  flex-direction: column;
}
.soai-july-i1 {
  object-fit: contain;
}
.soai-july-r1-r1-c2 {
  width: 65%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.soai-july-t1 {
  width: 80%;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-i2 {
  width: 80%;
  object-fit: contain;
}
.soai-july-r1-r1-c3 {
  width: 15%;
  display: flex;
  flex-direction: column;
}
.soai-july-i3 {
  margin-top: var(--dl-space-space-tenunits);
  object-fit: contain;
}
.soai-july-r1-r2 {
  display: flex;
  padding-top: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.soai-july-t2 {
  align-self: flex-end;
}
.soai-july-t3 {
  color: var(--dl-color-gray-white);
  width: 30%;
  height: 65px;
  padding: var(--dl-space-space-unit);
  text-align: center;
  transition: 0.3s;
  border-radius: 70px;
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.soai-july-r2 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.soai-july-r2-c1 {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.soai-july-t5 {
  padding-top: var(--dl-space-space-fiveunits);
}
.soai-july-r2-c2 {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.soai-july-i31 {
  width: 80%;
  align-self: center;
  object-fit: contain;
}
.soai-july-r3 {
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.soai-july-t6 {
  width: 75%;
  font-size: 45px;
  align-self: flex-end;
  font-family: Arial;
  font-weight: 400;
}
.soai-july-t7 {
  width: 75%;
  align-self: flex-end;
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-fiveunits);
}
.soai-july-r3-r1 {
  width: 75%;
  display: flex;
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.soai-july-link {
  display: contents;
}
.soai-july-r3-r1-c1 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r1-c1:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t8 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t9 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-link1 {
  display: contents;
}
.soai-july-r3-r1-c2 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r1-c2:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t81 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t91 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-link2 {
  display: contents;
}
.soai-july-r3-r1-c3 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  text-decoration: none;
}
.soai-july-r3-r1-c3:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t82 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t92 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-r3-r2 {
  width: 75%;
  display: flex;
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.soai-july-link3 {
  display: contents;
}
.soai-july-r3-r2-c1 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r2-c1:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t83 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t93 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-r3-r2-c3 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r2-c3:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t84 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t94 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-link4 {
  display: contents;
}
.soai-july-r3-r2-c4 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  text-decoration: none;
}
.soai-july-r3-r2-c4:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t85 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t95 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-r3-r3 {
  width: 75%;
  display: flex;
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.soai-july-link5 {
  display: contents;
}
.soai-july-r3-r3-c1 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r3-c1:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t86 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t96 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-link6 {
  display: contents;
}
.soai-july-r3-r3-c2 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r3-c2:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t87 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t97 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-link7 {
  display: contents;
}
.soai-july-r3-r3-c3 {
  width: 31%;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
}
.soai-july-r3-r3-c3:hover {
  color: var(--dl-color-feelers-accent);
}
.soai-july-t88 {
  margin-bottom: var(--dl-space-space-unit);
}
.soai-july-t98 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-r4 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.soai-july-r4-c1 {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.soai-july-t10 {
  font-size: 45px;
  font-family: Arial;
  font-weight: 400;
}
.soai-july-t11 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t12 {
  color: var(--dl-color-gray-white);
  width: 50%;
  height: 65px;
  padding: var(--dl-space-space-unit);
  align-self: center;
  margin-top: var(--dl-space-space-tripleunit);
  text-align: center;
  transition: 0.3s;
  border-radius: 70px;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.soai-july-t111 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t112 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-i4 {
  width: 18%;
  object-fit: contain;
}
.soai-july-r5 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: flex-end;
}
.soai-july-r5-c1 {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.soai-july-t101 {
  font-size: 45px;
  font-family: Arial;
  font-weight: 400;
}
.soai-july-t113 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text18 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text20 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text22 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text24 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t121 {
  align-self: flex-end;
}
.soai-july-t13 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text25 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text27 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text29 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t122 {
  align-self: flex-end;
}
.soai-july-t14 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text30 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text32 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text34 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text36 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t123 {
  align-self: flex-end;
}
.soai-july-t15 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text38 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text40 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text42 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t124 {
  align-self: flex-end;
}
.soai-july-t16 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text43 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text45 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text47 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-text49 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-july-t125 {
  align-self: flex-end;
}
.soai-july-r6 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.soai-july-i5 {
  width: 25%;
  object-fit: contain;
}
.soai-july-r6-c1 {
  width: 65%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.soai-july-t18 {
  color: var(--dl-color-gray-white);
  width: 30%;
  height: 56px;
  padding: var(--dl-space-space-doubleunit);
  align-self: center;
  margin-top: var(--dl-space-space-tripleunit);
  text-align: center;
  transition: 0.3s;
  border-radius: 70px;
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.soai-july-i6 {
  width: 20%;
  align-self: flex-end;
  object-fit: contain;
}
.soai-july-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 1200px) {
  .soai-july-t113 {
    text-transform: none;
  }
  .soai-july-t13 {
    text-transform: none;
  }
  .soai-july-t15 {
    font-size: 20px;
    text-transform: none;
    text-decoration: none;
  }
  .soai-july-t16 {
    font-size: 20px;
    text-transform: none;
    text-decoration: none;
  }
  .soai-july-t17 {
    text-align: center;
  }
  .soai-july-text50 {
    text-align: center;
  }
  .soai-july-text51 {
    text-align: center;
  }
  .soai-july-text52 {
    text-align: center;
  }
  .soai-july-text53 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .soai-july-r1 {
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-july-r1-r1 {
    align-items: center;
    flex-direction: column;
  }
  .soai-july-r1-r1-c2 {
    width: 100%;
  }
  .soai-july-t1 {
    width: 100%;
  }
  .soai-july-i2 {
    width: 100%;
  }
  .soai-july-i3 {
    margin-top: 0;
  }
  .soai-july-r1-r2 {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  .soai-july-t2 {
    align-self: center;
    text-align: center;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .soai-july-t3 {
    width: 60%;
  }
  .soai-july-r2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-july-r2-c1 {
    width: 100%;
  }
  .soai-july-t4 {
    padding-left: 0;
  }
  .soai-july-t5 {
    padding-left: 0;
  }
  .soai-july-text07 {
    padding-left: 0;
  }
  .soai-july-text09 {
    padding-left: 0;
  }
  .soai-july-text11 {
    padding-left: 0;
  }
  .soai-july-text13 {
    padding-left: 0;
  }
  .soai-july-r2-c2 {
    width: 60%;
    align-self: center;
    margin-top: var(--dl-space-space-fourunits);
  }
  .soai-july-r3 {
    align-items: center;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-july-t6 {
    width: 100%;
  }
  .soai-july-t7 {
    width: 100%;
  }
  .soai-july-r3-r1 {
    width: 100%;
    margin-bottom: 0;
    flex-direction: column;
  }
  .soai-july-r3-r1-c1 {
    width: 100%;
  }
  .soai-july-r3-r1-c2 {
    width: 100%;
  }
  .soai-july-r3-r1-c3 {
    width: 100%;
  }
  .soai-july-r3-r2 {
    width: 100%;
    margin-bottom: 0;
    flex-direction: column;
  }
  .soai-july-r3-r2-c1 {
    width: 100%;
  }
  .soai-july-r3-r2-c3 {
    width: 100%;
  }
  .soai-july-r3-r2-c4 {
    width: 100%;
  }
  .soai-july-r3-r3 {
    width: 100%;
    margin-bottom: 0;
    flex-direction: column;
  }
  .soai-july-r3-r3-c1 {
    width: 100%;
  }
  .soai-july-r3-r3-c2 {
    width: 100%;
  }
  .soai-july-r3-r3-c3 {
    width: 100%;
  }
  .soai-july-r4 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-july-r4-c1 {
    width: 100%;
  }
  .soai-july-t12 {
    width: 70%;
  }
  .soai-july-i4 {
    width: 40%;
    align-self: center;
    margin-top: var(--dl-space-space-fourunits);
  }
  .soai-july-r5 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-july-r5-c1 {
    width: 100%;
  }
  .soai-july-r6 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-july-i5 {
    width: 50%;
    align-self: center;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .soai-july-r6-c1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .soai-july-t18 {
    width: 60%;
  }
  .soai-july-i6 {
    width: 50%;
    align-self: center;
  }
}
