.tb01-main-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tb01-main-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
  background-color: var(--dl-color-gray-white);
}
.tb01-main-image {
  left: 0px;
  right: 0px;
  width: 50%;
  margin: auto;
  opacity: 0.6;
  position: absolute;
  align-self: center;
  object-fit: cover;
}
.tb01-main-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.tb01-main-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.tb01-main-r1 {
  width: 100%;
  display: flex;
  margin-top: 180px;
  padding-left: var(--dl-space-space-tenunits);
  margin-bottom: 120px;
  padding-right: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.tb01-main-t1 {
  color: var(--dl-color-touchbase-dblue);
  width: 50%;
  margin-top: 200px;
  transition: 0.3s;
  text-decoration: none;
}
.tb01-main-t1:hover {
  color: var(--dl-color-touchbase-lblue);
}
.tb01-main-r1-c2 {
  width: 45%;
  display: flex;
  padding-left: 140px;
  padding-right: 140px;
  flex-direction: column;
}
.tb01-main-t2 {
  color: var(--dl-color-touchbase-lblue);
}
.tb01-main-t3 {
  color: var(--dl-color-touchbase-dblue);
}
.tb01-main-t4 {
  color: var(--dl-color-touchbase-lblue);
  align-self: flex-end;
  margin-top: 150px;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-touchbase-lblue);
  border-width: 2px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.tb01-main-t4:hover {
  color: var(--dl-color-touchbase-lgreen);
  border-color: var(--dl-color-touchbase-lgreen);
}
.tb01-main-r3 {
  width: 100%;
  display: none;
  margin-top: 200px;
  flex-direction: column;
}
.tb01-main-r3-r1 {
  display: flex;
  padding-left: var(--dl-space-space-tenunits);
}
.tb01-main-t41 {
  color: var(--dl-color-touchbase-lblue);
}
.tb01-main-i1 {
  width: 30px;
  transform: rotate(90deg);
  align-self: flex-end;
  margin-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.tb01-main-r3-r2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-touchbase-lgreen);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.tb01-main-t5 {
  color: var(--dl-color-touchbase-lgreen);
}
.tb01-main-i2 {
  width: 30px;
  height: 30px;
  align-self: center;
}
.tb01-main-r3-r3 {
  display: flex;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-touchbase-lgreen);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.tb01-main-t51 {
  color: var(--dl-color-touchbase-lgreen);
}
.tb01-main-i21 {
  width: 30px;
  height: 30px;
  align-self: center;
}
.tb01-main-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .tb01-main-image {
    width: 100%;
  }
  .tb01-main-body-container {
    flex-direction: column;
  }
  .tb01-main-r1 {
    margin-top: var(--dl-space-space-tenunits);
    align-items: center;
    margin-bottom: 200px;
    flex-direction: column;
  }
  .tb01-main-t1 {
    margin-top: 0px;
    text-align: center;
  }
  .tb01-main-r1-c2 {
    width: 80%;
    margin-top: var(--dl-space-space-tenunits);
    padding-left: 0px;
    padding-right: 0px;
  }
  .tb01-main-t4 {
    align-self: center;
    margin-top: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .tb01-main-t1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .tb01-main-r1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .tb01-main-t1 {
    text-align: center;
  }
}
