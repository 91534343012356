@font-face {
  font-family: "ForPersonalGain-ForNon-Profit";
  src: url("/fonts/ForPersonalGain-ForNon-Profit.woff") format("woff");
}
@font-face {
  font-family: "ForPersonalGain-ForProfit";
  src: url("/fonts/ForPersonalGain-ForProfit.woff") format("woff");
}
@font-face {
  font-family: "Frank";
  font-style: normal;
  font-format: woff;
  font-weight: 400;
  src: url("/fonts/frank-regular.woff");
}
@font-face {
  font-family: "OCR B Std";
  font-style: normal;
  font-format: woff;
  font-weight: 400;
  src: url("/fonts/ocr b std.woff");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-format: woff;
  font-weight: 400;
  src: url("/fonts/rubik-regular.woff");
}
@font-face {
  font-family: "Rubik SemiBold";
  font-style: normal;
  font-format: woff;
  font-weight: 600;
  src: url("/fonts/rubik-bold.woff");
}
:root {
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-space-space-unit: 8px;
  --dl-color-totapo-grey: #707070ff;
  --dl-color-totapo-sand: #d3cfbcff;
  --dl-color-feelers-dark: #009a6bff;
  --dl-color-totapo-slate: #f2f2f2ff;
  --dl-color-feelers-light: #4dd820ff;
  --dl-color-totapo-orange: #ff7a17ff;
  --dl-color-totapo-yellow: #ffd41fff;
  --dl-color-feelers-accent: #ff0066ff;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-tenunits: 80px;
  --dl-color-touchbase-dblue: #1c2431ff;
  --dl-color-touchbase-lblue: #567cb1ff;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-fourunits: 32px;
  --dl-color-touchbase-dgreen: #83853eff;
  --dl-color-touchbase-lgreen: #c5c773ff;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
  --dl-color-touchbase-background: #fcf9f7ff;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.list {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Arial;
  border-radius: 20px;
  background-color: var(--dl-color-totapo-orange);
}
.list-item {
  color: var(--dl-color-feelers-dark);
  width: 95%;
  display: flex;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.list-item:hover {
  color: var(--dl-color-feelers-accent);
  width: 100%;
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.TB-OCR-XXL {
  font-size: 70px;
  font-family: "OCR B Std";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-F-L {
  font-size: 30px;
  font-family: "Frank";
  font-weight: 400;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.TB-F-XL {
  font-size: 60px;
  font-family: "Frank";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-OCR-L {
  font-size: 40px;
  font-family: "OCR B Std";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-OCR-M {
  font-size: 24px;
  font-family: "OCR B Std";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-F-M {
  font-size: 18px;
  font-family: "Frank";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-OCR-S {
  font-size: 16px;
  font-family: "OCR B Std";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-F-S {
  font-size: 14px;
  font-family: "Frank";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.TB-OCR-XL {
  font-size: 60px;
  font-family: "OCR B Std";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.Alt2-L {
  font-size: 70px;
  font-family: ForPersonalGain-ForNon-Profit;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.Body-S {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.Body-XL {
  font-size: 60px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.1;
  text-transform: none;
  text-decoration: none;
}
.Title-M {
  font-size: 16px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}
.Body-XS {
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.Alt1-M {
  font-size: 22px;
  font-family: ForPersonalGain-ForProfit;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.Body-M {
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.Title-XXL {
  font-size: 64px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}
.Body-XXL {
  font-size: 80px;
  font-family: Arial;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -1px;
  text-transform: none;
  text-decoration: none;
}
.Title-L {
  font-size: 22px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
}
.Alt1-XS {
  font-size: 12px;
  font-family: ForPersonalGain-ForProfit;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.Navbar-Link {
  font-size: 20px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.Body-L {
  font-size: 32px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.Alt1-L {
  font-size: 70px;
  font-family: ForPersonalGain-ForProfit;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.Title-XXXL {
  font-size: 180px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}
.SoAI-MB {
  font-size: 20px;
  font-family: "Rubik SemiBold";
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}
.Ttile-S {
  font-size: 14px;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Alt1-S {
  font-size: 14px;
  font-family: ForPersonalGain-ForProfit;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.SoAI-M {
  font-size: 20px;
  font-family: "Rubik";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
@media (max-width: 767px) {
  .list-item {
    margin-bottom: var(--dl-space-space-fiveunits);
  }
}
