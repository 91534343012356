.tb-program-item-container {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.tb-program-item-programtitle {
  color: var(--dl-color-touchbase-dgreen);
}
.tb-program-item-programimg {
  object-fit: cover;
}
.tb-program-item-programno {
  color: var(--dl-color-touchbase-lgreen);
  align-self: flex-end;
  margin-top: -40px;
  margin-right: var(--dl-space-space-unit);
}



























































