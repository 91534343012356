.services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.services-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.services-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.services-image1 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.services-image2 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.services-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.services-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.services-top-container {
  display: flex;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.services-text {
  color: var(--dl-color-feelers-dark);
}
.services-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.services-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.services-col1-container {
  width: 15%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
}
.services-text01 {
  color: var(--dl-color-feelers-dark);
}
.services-col234-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.services-row1-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text02 {
  color: var(--dl-color-feelers-dark);
}
.services-row2-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.services-col2-container01 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text03 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-unit);
}
.services-text04 {
  color: var(--dl-color-feelers-dark);
  padding-left: var(--dl-space-space-fiveunits);
}
.services-text05 {
  color: var(--dl-color-feelers-dark);
  margin-left: var(--dl-space-space-fiveunits);
}
.services-col3-container {
  width: 26%;
  display: flex;
  flex-direction: column;
}
.services-text06 {
  color: var(--dl-color-feelers-dark);
}
.services-text07 {
  color: var(--dl-color-feelers-dark);
}
.services-text08 {
  color: var(--dl-color-feelers-dark);
}
.services-col4-container {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-text09 {
  color: var(--dl-color-feelers-dark);
}
.services-text10 {
  color: var(--dl-color-feelers-dark);
}
.services-row3-container {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container02 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text11 {
  color: var(--dl-color-feelers-dark);
}
.services-row4-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.services-col2-container03 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text12 {
  color: var(--dl-color-feelers-dark);
}
.services-col3-container1 {
  width: 26%;
  display: flex;
  flex-direction: column;
}
.services-physical {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  width: 100%;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.services-physical:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-dropdown-toggle {
  display: inline-flex;
  transition: 0.3s;
}
.services-dropdown-toggle:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-icon {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-dropdown-list {
  color: var(--dl-color-feelers-dark);
  display: none;
  flex-direction: column;
}
.services-dropdown {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle01 {
  padding: var(--dl-space-space-unit);
  padding-left: 0;
}
.services-text14 {
  font-style: italic;
}
.services-dropdown01 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown01:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle02 {
  padding: var(--dl-space-space-unit);
}
.services-text15 {
  display: flex;
}
.services-text16 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown02 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown02:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle03 {
  padding: var(--dl-space-space-unit);
}
.services-text17 {
  display: flex;
}
.services-text18 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown03 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown03:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle04 {
  padding: var(--dl-space-space-unit);
}
.services-text19 {
  display: flex;
}
.services-text20 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown04 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown04:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle05 {
  padding: var(--dl-space-space-unit);
}
.services-text21 {
  display: flex;
}
.services-text22 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-digital {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  width: 100%;
  display: inline-block;
  margin-bottom: 0;
}
.services-digital:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-dropdown-toggle06 {
  display: inline-flex;
  transition: 0.3s;
}
.services-dropdown-toggle06:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-icon02 {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-dropdown-list1 {
  color: var(--dl-color-feelers-dark);
  display: none;
  flex-direction: column;
}
.services-dropdown05 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown05:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle07 {
  padding: var(--dl-space-space-unit);
  padding-left: 0;
}
.services-text24 {
  display: flex;
  font-style: italic;
}
.services-dropdown06 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown06:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle08 {
  padding: var(--dl-space-space-unit);
}
.services-text25 {
  display: flex;
}
.services-text26 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown07 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown07:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle09 {
  padding: var(--dl-space-space-unit);
}
.services-text27 {
  display: flex;
}
.services-text28 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown08 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown08:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle10 {
  padding: var(--dl-space-space-unit);
}
.services-text29 {
  display: flex;
}
.services-text30 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-col4-container1 {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-text31 {
  color: var(--dl-color-feelers-dark);
}
.services-text32 {
  color: var(--dl-color-feelers-dark);
}
.services-row5-container {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container04 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text33 {
  color: var(--dl-color-feelers-dark);
}
.services-row6-container {
  display: flex;
  justify-content: space-between;
}
.services-col2-container05 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text34 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-unit);
}
.services-col3-container2 {
  width: 26%;
  display: flex;
  flex-direction: column;
}
.services-ashley {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  width: 100%;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.services-ashley:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-dropdown-toggle11 {
  display: inline-flex;
  transition: 0.3s;
}
.services-dropdown-toggle11:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-icon04 {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-dropdown-list2 {
  color: var(--dl-color-feelers-dark);
  display: none;
  flex-direction: column;
}
.services-dropdown09 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown09:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle12 {
  padding: var(--dl-space-space-unit);
}
.services-text36 {
  display: flex;
}
.services-text37 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown10 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown10:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle13 {
  padding: var(--dl-space-space-unit);
}
.services-text38 {
  display: flex;
}
.services-text39 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-sixuan {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  width: 100%;
  display: inline-block;
  margin-bottom: 0;
}
.services-sixuan:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-dropdown-toggle14 {
  display: inline-flex;
  transition: 0.3s;
}
.services-dropdown-toggle14:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-icon06 {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-dropdown-list3 {
  color: var(--dl-color-feelers-dark);
  display: none;
  flex-direction: column;
}
.services-dropdown11 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown11:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle15 {
  padding: var(--dl-space-space-unit);
}
.services-text41 {
  display: flex;
}
.services-text42 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-dropdown12 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown12:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle16 {
  padding: var(--dl-space-space-unit);
}
.services-text43 {
  display: flex;
}
.services-text44 {
  display: flex;
  padding-left: var(--dl-space-space-doubleunit);
}
.services-col4-container2 {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-standard {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  width: 100%;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.services-standard:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-dropdown-toggle17 {
  display: inline-flex;
  transition: 0.3s;
}
.services-dropdown-toggle17:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-icon08 {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-dropdown-list4 {
  color: var(--dl-color-feelers-dark);
  display: none;
  flex-direction: column;
}
.services-dropdown13 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown13:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle18 {
  padding: var(--dl-space-space-unit);
  padding-left: 0;
}
.services-text46 {
  font-style: italic;
}
.services-dropdown14 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown14:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle19 {
  padding: var(--dl-space-space-unit);
}
.services-text47 {
  display: flex;
}
.services-text48 {
  display: flex;
}
.services-text49 {
  display: flex;
}
.services-text50 {
  display: flex;
}
.services-emergency {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  width: 100%;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.services-emergency:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-dropdown-toggle20 {
  display: inline-flex;
  transition: 0.3s;
}
.services-dropdown-toggle20:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-icon10 {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-dropdown-list5 {
  color: var(--dl-color-feelers-dark);
  display: none;
  flex-direction: column;
}
.services-dropdown15 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown15:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle21 {
  padding: var(--dl-space-space-unit);
  padding-left: 0;
}
.services-text52 {
  font-style: italic;
}
.services-dropdown16 {
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.services-dropdown16:hover {
  color: var(--dl-color-feelers-dark);
}
.services-dropdown-toggle22 {
  padding: var(--dl-space-space-unit);
}
.services-text53 {
  display: flex;
}
.services-row2-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.services-col1-container1 {
  width: 15%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
}
.services-text54 {
  color: var(--dl-color-feelers-dark);
}
.services-col234-container1 {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.services-row1-container2 {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container06 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text55 {
  color: var(--dl-color-feelers-dark);
}
.services-row2-container2 {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.services-col2-container07 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text56 {
  color: var(--dl-color-feelers-dark);
}
.services-col3-container3 {
  width: 26%;
  display: flex;
  flex-direction: column;
}
.services-text57 {
  color: var(--dl-color-feelers-dark);
}
.services-col4-container3 {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-text58 {
  color: var(--dl-color-feelers-dark);
}
.services-text59 {
  color: var(--dl-color-feelers-dark);
}
.services-row3-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container08 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text60 {
  color: var(--dl-color-feelers-dark);
}
.services-row4-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.services-col2-container09 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text61 {
  color: var(--dl-color-feelers-dark);
}
.services-col3-container4 {
  width: 26%;
  display: flex;
  flex-direction: column;
}
.services-col4-container4 {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-text62 {
  color: var(--dl-color-feelers-dark);
}
.services-text63 {
  color: var(--dl-color-feelers-dark);
}
.services-row5-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container10 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text64 {
  color: var(--dl-color-feelers-dark);
}
.services-row6-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.services-col2-container11 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text65 {
  color: var(--dl-color-feelers-dark);
}
.services-col3-container5 {
  width: 26%;
  display: flex;
  flex-direction: column;
}
.services-text66 {
  color: var(--dl-color-feelers-dark);
}
.services-col4-container5 {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-text67 {
  color: var(--dl-color-feelers-dark);
}
.services-text68 {
  color: var(--dl-color-feelers-dark);
}
.services-row3-container2 {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.services-col1-container2 {
  width: 15%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
}
.services-text69 {
  color: var(--dl-color-feelers-dark);
}
.services-col234-container2 {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.services-row1-container3 {
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.services-col2-container12 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-navlink {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  text-decoration: none;
}
.services-navlink:hover {
  color: var(--dl-color-feelers-accent);
}
.services-row2-container3 {
  display: flex;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.services-col2-container13 {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.services-text70 {
  color: var(--dl-color-feelers-dark);
}
.services-col3-container6 {
  width: 26%;
  display: flex;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.services-navlink1 {
  display: contents;
}
.services-info {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  height: fit-content;
  display: flex;
  transition: 0.3s;
  flex-direction: row;
  text-decoration: none;
}
.services-info:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.services-text71 {
  color: inherit;
}
.services-icon12 {
  fill: inherit;
  width: 18px;
  height: 18px;
}
.services-col4-container6 {
  gap: var(--dl-space-space-doubleunit);
  width: 28%;
  display: flex;
  flex-direction: column;
}
.services-text72 {
  color: var(--dl-color-feelers-dark);
}
.services-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .services-body-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .services-col2-container {
    width: 100%;
  }
  .services-row2-container {
    flex-direction: column;
  }
  .services-col2-container01 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .services-text06 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-text07 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-text08 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-col4-container {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .services-col2-container02 {
    width: 100%;
  }
  .services-row4-container {
    flex-direction: column;
  }
  .services-col2-container03 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-text13 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-dropdown-list {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-text23 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-dropdown-list1 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-col4-container1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col2-container04 {
    width: 100%;
  }
  .services-row6-container {
    flex-direction: column;
  }
  .services-col2-container05 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-text35 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-dropdown-list2 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-text40 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-dropdown-list3 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-col4-container2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-row1-container2 {
    flex-direction: column;
  }
  .services-col2-container06 {
    width: 100%;
  }
  .services-row2-container2 {
    flex-direction: column;
  }
  .services-col2-container07 {
    width: 100%;
  }
  .services-text56 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-text57 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-col4-container3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-row3-container1 {
    flex-direction: column;
  }
  .services-col2-container08 {
    width: 100%;
  }
  .services-row4-container1 {
    flex-direction: column;
  }
  .services-col2-container09 {
    width: 100%;
  }
  .services-text61 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container4 {
    width: 100%;
  }
  .services-col4-container4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-row5-container1 {
    flex-direction: column;
  }
  .services-col2-container10 {
    width: 100%;
  }
  .services-row6-container1 {
    flex-direction: column;
  }
  .services-col2-container11 {
    width: 100%;
  }
  .services-text65 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-text66 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-col4-container5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-row1-container3 {
    flex-direction: column;
  }
  .services-col2-container12 {
    width: 100%;
  }
  .services-row2-container3 {
    flex-direction: column;
  }
  .services-col2-container13 {
    width: 100%;
  }
  .services-text70 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .services-col3-container6 {
    width: 100%;
  }
  .services-text71 {
    padding-left: var(--dl-space-space-fiveunits);
  }
  .services-col4-container6 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .services-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .services-row1-container {
    flex-direction: column;
  }
  .services-col1-container {
    width: 100%;
  }
  .services-col234-container {
    width: 100%;
  }
  .services-text09 {
    width: 100%;
  }
  .services-text10 {
    width: 100%;
  }
  .services-text31 {
    width: 100%;
  }
  .services-text32 {
    width: 100%;
  }
  .services-row2-container1 {
    flex-direction: column;
  }
  .services-col1-container1 {
    width: 100%;
  }
  .services-col234-container1 {
    width: 100%;
  }
  .services-text58 {
    width: 100%;
  }
  .services-text59 {
    width: 100%;
  }
  .services-text62 {
    width: 100%;
  }
  .services-text63 {
    width: 100%;
  }
  .services-text67 {
    width: 100%;
  }
  .services-text68 {
    width: 100%;
  }
  .services-row3-container2 {
    flex-direction: column;
  }
  .services-col1-container2 {
    width: 100%;
  }
  .services-col234-container2 {
    width: 100%;
  }
  .services-text72 {
    width: 100%;
  }
}
