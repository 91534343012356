.interactive-rotary-phone-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.interactive-rotary-phone-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.interactive-rotary-phone-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.interactive-rotary-phone-image1 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.interactive-rotary-phone-image2 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.interactive-rotary-phone-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.interactive-rotary-phone-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.interactive-rotary-phone-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.interactive-rotary-phone-title {
  color: var(--dl-color-feelers-dark);
  padding-bottom: var(--dl-space-space-unit);
}
.interactive-rotary-phone-img-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.interactive-rotary-phone-image3 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.interactive-rotary-phone-image4 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.interactive-rotary-phone-iframe-container {
  position: relative;
  padding-bottom: 177%;
}
.interactive-rotary-phone-container1 {
  width: 100%;
  height: 100%;
  position: absolute;
}
.interactive-rotary-phone-container2 {
  display: contents;
}
.interactive-rotary-phone-right-container {
  width: 60%;
  display: flex;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.interactive-rotary-phone-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.interactive-rotary-phone-row1-container {
  display: flex;
  justify-content: space-between;
}
.interactive-rotary-phone-text {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  font-style: italic;
  margin-top: var(--dl-space-space-doubleunit);
  padding-top: var(--dl-space-space-halfunit);
}
.interactive-rotary-phone-text01 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.interactive-rotary-phone-row2-container {
  display: flex;
  justify-content: space-between;
}
.interactive-rotary-phone-col1-container {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.interactive-rotary-phone-col2-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.interactive-rotary-phone-text07 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tenunits);
}
.interactive-rotary-phone-text08 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.interactive-rotary-phone-text13 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.interactive-rotary-phone-link {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
}
.interactive-rotary-phone-link:hover {
  color: var(--dl-color-feelers-accent);
}
.interactive-rotary-phone-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .interactive-rotary-phone-body-container {
    flex-direction: column;
  }
  .interactive-rotary-phone-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .interactive-rotary-phone-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
  }
}
@media(max-width: 767px) {
  .interactive-rotary-phone-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .interactive-rotary-phone-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .interactive-rotary-phone-row1-container {
    flex-direction: column;
  }
  .interactive-rotary-phone-text {
    width: 100%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .interactive-rotary-phone-text01 {
    width: 100%;
  }
  .interactive-rotary-phone-row2-container {
    flex-direction: column;
  }
  .interactive-rotary-phone-col2-container {
    width: 100%;
  }
}
