.good-am-to-you-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.good-am-to-you-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.good-am-to-you-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.good-am-to-you-image1 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.good-am-to-you-image2 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.good-am-to-you-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.good-am-to-you-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.good-am-to-you-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.good-am-to-you-title {
  color: var(--dl-color-feelers-dark);
}
.good-am-to-you-img-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-image3 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.good-am-to-you-image4 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.good-am-to-you-image5 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.good-am-to-you-right-container {
  width: 60%;
  display: flex;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.good-am-to-you-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.good-am-to-you-col1-container {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-text {
  color: var(--dl-color-feelers-dark);
  font-style: italic;
  text-align: right;
  padding-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.good-am-to-you-text07 {
  color: var(--dl-color-feelers-dark);
  text-align: right;
}
.good-am-to-you-col2-container {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-text08 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.good-am-to-you-text09 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.good-am-to-you-link {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.good-am-to-you-link:hover {
  color: var(--dl-color-feelers-accent);
}
.good-am-to-you-link1 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.good-am-to-you-link1:hover {
  color: var(--dl-color-feelers-accent);
}
.good-am-to-you-link2 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.good-am-to-you-link2:hover {
  color: var(--dl-color-feelers-accent);
}
.good-am-to-you-row2-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.good-am-to-you-col1-container1 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-text14 {
  color: var(--dl-color-feelers-dark);
  font-style: italic;
  text-align: right;
  padding-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.good-am-to-you-text23 {
  color: var(--dl-color-feelers-dark);
  text-align: right;
}
.good-am-to-you-col2-container1 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-text24 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.good-am-to-you-text25 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.good-am-to-you-row3-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.good-am-to-you-col1-container2 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.good-am-to-you-col2-container2 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-text26 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.good-am-to-you-row4-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.good-am-to-you-col1-container3 {
  gap: var(--dl-space-space-unit);
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.good-am-to-you-col2-container3 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.good-am-to-you-text27 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.good-am-to-you-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .good-am-to-you-body-container {
    flex-direction: column;
  }
  .good-am-to-you-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .good-am-to-you-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .good-am-to-you-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .good-am-to-you-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .good-am-to-you-row1-container {
    flex-direction: column;
  }
  .good-am-to-you-col1-container {
    width: 100%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .good-am-to-you-text {
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .good-am-to-you-col2-container {
    width: 100%;
  }
  .good-am-to-you-row2-container {
    flex-direction: column;
  }
  .good-am-to-you-col1-container1 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .good-am-to-you-text14 {
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .good-am-to-you-col2-container1 {
    width: 100%;
  }
  .good-am-to-you-row3-container {
    flex-direction: column;
  }
  .good-am-to-you-col1-container2 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .good-am-to-you-col2-container2 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .good-am-to-you-row4-container {
    flex-direction: column;
  }
  .good-am-to-you-col1-container3 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .good-am-to-you-col2-container3 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
}
