.tb-credit-container {
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.tb-credit-text {
  color: var(--dl-color-touchbase-dblue);
  margin-bottom: var(--dl-space-space-unit);
}
.tb-credit-image {
  width: 100%;
  object-fit: cover;
}
.tb-credit-text1 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-doubleunit);
}
.tb-credit-root-class-name {
  height: auto;
}
.tb-credit-root-class-name1 {
  height: auto;
}
.tb-credit-root-class-name2 {
  height: auto;
}
.tb-credit-root-class-name3 {
  height: auto;
}
.tb-credit-root-class-name4 {
  height: auto;
}
.tb-credit-root-class-name5 {
  height: auto;
}
.tb-credit-root-class-name6 {
  height: auto;
}
