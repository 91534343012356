.soai-lite-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.soai-lite-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.soai-lite-body {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.soai-lite-r1 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.soai-lite-t1 {
  width: 70%;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-i2 {
  width: 70%;
  align-self: center;
  object-fit: contain;
}
.soai-lite-t2 {
  width: 80%;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-r2 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.soai-lite-r2-c1 {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.soai-lite-t5 {
  padding-top: var(--dl-space-space-fiveunits);
}
.soai-lite-r1-r2 {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.soai-lite-t3 {
  color: var(--dl-color-gray-white);
  height: 55px;
  padding: var(--dl-space-space-doubleunit);
  text-align: center;
  transition: 0.3s;
  border-radius: 70px;
  background-color: var(--dl-color-gray-black);
}
.soai-lite-t41 {
  text-align: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-tripleunit);
}
.soai-lite-r3 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: flex-end;
}
.soai-lite-r3-c1 {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.soai-lite-t10 {
  font-size: 45px;
  font-family: Arial;
  font-weight: 400;
}
.soai-lite-t12 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
}
.soai-lite-t11 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-t121 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
}
.soai-lite-t111 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-t122 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
}
.soai-lite-t112 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-t123 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
}
.soai-lite-t113 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-text10 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.soai-lite-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 1200px) {
  .soai-lite-r1-r2 {
    width: 35%;
  }
  .soai-lite-t10 {
    font-size: 45px;
    font-family: Arial;
    font-weight: 400;
  }
}
@media(max-width: 767px) {
  .soai-lite-r1 {
    align-items: center;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-lite-t1 {
    width: 100%;
  }
  .soai-lite-i2 {
    width: 100%;
  }
  .soai-lite-t2 {
    width: 100%;
  }
  .soai-lite-r2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-lite-r2-c1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .soai-lite-t4 {
    padding-left: 0;
  }
  .soai-lite-t5 {
    padding-left: 0;
  }
  .soai-lite-text {
    padding-left: 0;
  }
  .soai-lite-text02 {
    padding-left: 0;
  }
  .soai-lite-text04 {
    padding-left: 0;
  }
  .soai-lite-text06 {
    padding-left: 0;
  }
  .soai-lite-r1-r2 {
    width: 100%;
    align-self: center;
  }
  .soai-lite-r3 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .soai-lite-r3-c1 {
    width: 100%;
  }
}
