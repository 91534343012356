.another-world-is-possible-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.another-world-is-possible-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.another-world-is-possible-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.another-world-is-possible-image1 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.another-world-is-possible-image2 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.another-world-is-possible-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.another-world-is-possible-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.another-world-is-possible-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.another-world-is-possible-title {
  color: var(--dl-color-feelers-dark);
}
.another-world-is-possible-img-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-image3 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.another-world-is-possible-col2 {
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.another-world-is-possible-image4 {
  width: 47%;
  object-fit: contain;
}
.another-world-is-possible-image5 {
  width: 47%;
  object-fit: contain;
}
.another-world-is-possible-image6 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.another-world-is-possible-right-container {
  width: 60%;
  display: flex;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.another-world-is-possible-header-container {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.another-world-is-possible-text {
  color: var(--dl-color-feelers-dark);
}
.another-world-is-possible-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.another-world-is-possible-left-container1 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-text01 {
  color: var(--dl-color-feelers-dark);
  font-style: italic;
  text-align: right;
  padding-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.another-world-is-possible-link {
  color: var(--dl-color-feelers-dark);
  text-align: right;
  transition: 0.3s;
}
.another-world-is-possible-link:hover {
  color: var(--dl-color-feelers-accent);
}
.another-world-is-possible-text10 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
}
.another-world-is-possible-row2-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.another-world-is-possible-col1-container {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.another-world-is-possible-col2-container {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-text19 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.another-world-is-possible-row3-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.another-world-is-possible-col1-container1 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.another-world-is-possible-col2-container1 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-text24 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.another-world-is-possible-row4-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.another-world-is-possible-col1-container2 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.another-world-is-possible-col2-container2 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-text32 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.another-world-is-possible-row5-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.another-world-is-possible-col1-container3 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.another-world-is-possible-col2-container3 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.another-world-is-possible-text40 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.another-world-is-possible-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .another-world-is-possible-body-container {
    flex-direction: column;
  }
  .another-world-is-possible-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .another-world-is-possible-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .another-world-is-possible-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .another-world-is-possible-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .another-world-is-possible-row1-container {
    flex-direction: column;
  }
  .another-world-is-possible-left-container1 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .another-world-is-possible-text01 {
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .another-world-is-possible-text10 {
    width: 100%;
  }
  .another-world-is-possible-row2-container {
    flex-direction: column;
  }
  .another-world-is-possible-col1-container {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-col2-container {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-row3-container {
    flex-direction: column;
  }
  .another-world-is-possible-col1-container1 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-col2-container1 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-row4-container {
    flex-direction: column;
  }
  .another-world-is-possible-col1-container2 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-col2-container2 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-row5-container {
    flex-direction: column;
  }
  .another-world-is-possible-col1-container3 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .another-world-is-possible-col2-container3 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
}
