.tb03-programs2-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tb03-programs2-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.tb03-programs2-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.tb03-programs2-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: fixed;
  margin-top: 100px;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.tb03-programs2-r1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tb03-programs2-t1 {
  color: var(--dl-color-touchbase-lblue);
}
.tb03-programs2-i1 {
  width: 30px;
  object-fit: cover;
}
.tb03-programs2-r2 {
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
}
.tb03-programs2-r3 {
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 380px;
}
.tb03-programs2-r4 {
  width: 25%;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  margin-right: 200px;
}
.tb03-programs2-r5 {
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 450px;
}
.tb03-programs2-r6 {
  width: 25%;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
}
.tb03-programs2-pop-container {
  width: 75%;
  display: flex;
  z-index: 2;
  position: relative;
  box-shadow: 5px 5px 10px 0px #c5c773;
  margin-top: 180px;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: var(--dl-color-touchbase-lgreen);
  border-style: solid;
  border-width: 4px;
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 40px;
  margin-bottom: 180px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
  background-color: var(--dl-color-touchbase-background);
}
.tb03-programs2-c1 {
  width: 45%;
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.tb03-programs2-link {
  color: var(--dl-color-touchbase-dblue);
  display: none;
  align-self: flex-end;
}
.tb03-programs2-link:hover {
  color: var(--dl-color-touchbase-lgreen);
}
.tb03-programs2-text {
  color: var(--dl-color-touchbase-lgreen);
}
.tb03-programs2-text01 {
  color: var(--dl-color-touchbase-dblue);
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.tb03-programs2-image {
  width: 100%;
  object-fit: cover;
}
.tb03-programs2-c2 {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tb03-programs2-navlink {
  color: var(--dl-color-touchbase-dblue);
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.tb03-programs2-navlink:hover {
  color: var(--dl-color-touchbase-lgreen);
}
.tb03-programs2-text09 {
  color: var(--dl-color-touchbase-dblue);
}
.tb03-programs2-text19 {
  color: var(--dl-color-touchbase-lgreen);
  margin-top: 250px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.tb03-programs2-link1 {
  color: var(--dl-color-touchbase-background);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 15px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-touchbase-lgreen);
}
@media(max-width: 991px) {
  .tb03-programs2-body-container {
    flex-direction: column;
  }
  .tb03-programs2-r2 {
    display: none;
  }
  .tb03-programs2-r3 {
    display: none;
  }
  .tb03-programs2-r4 {
    display: none;
  }
  .tb03-programs2-r5 {
    display: none;
  }
  .tb03-programs2-r6 {
    display: none;
  }
  .tb03-programs2-pop-container {
    width: 90%;
    flex-direction: column;
  }
  .tb03-programs2-c1 {
    width: 100%;
    padding-top: 0px;
  }
  .tb03-programs2-link {
    display: flex;
  }
  .tb03-programs2-text01 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .tb03-programs2-c2 {
    width: 100%;
  }
  .tb03-programs2-navlink {
    display: none;
  }
  .tb03-programs2-text09 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .tb03-programs2-text19 {
    margin-top: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .tb03-programs2-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .tb03-programs2-pop-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 479px) {
  .tb03-programs2-t1 {
    font-size: 45px;
  }
}
