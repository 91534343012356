.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.contact-image {
  top: 20%;
  right: -4%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.contact-image1 {
  left: 25%;
  width: 400px;
  bottom: 10%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.contact-navbar-container {
  width: 100%;
  display: flex;
  z-index: 2;
  position: fixed;
  flex-direction: column;
}
.contact-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 380px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.contact-text {
  color: var(--dl-color-feelers-dark);
}
.contact-text1 {
  color: var(--dl-color-feelers-dark);
  align-self: center;
  margin-top: var(--dl-space-space-tenunits);
}
.contact-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 2;
  position: relative;
}
@media(max-width: 767px) {
  .contact-body-container {
    padding-right: 100px;
  }
}
@media(max-width: 479px) {
  .contact-body-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
}
