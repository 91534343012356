.tb-navbar-navbar {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.tb-navbar-navlink {
  display: contents;
}
.tb-navbar-logo {
  width: 50px;
  align-self: flex-start;
  object-fit: cover;
  text-decoration: none;
}
.tb-navbar-links-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.tb-navbar-link {
  color: var(--dl-color-touchbase-lblue);
}
.tb-navbar-link:hover {
  color: var(--dl-color-touchbase-lgreen);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-touchbase-lgreen);
  border-width: 2px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.tb-navbar-link1 {
  color: var(--dl-color-touchbase-lblue);
}
.tb-navbar-link1:hover {
  color: var(--dl-color-touchbase-lgreen);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-touchbase-lgreen);
  border-width: 2px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.tb-navbar-link2 {
  color: var(--dl-color-touchbase-lblue);
}
.tb-navbar-link2:hover {
  color: var(--dl-color-touchbase-lgreen);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-touchbase-lgreen);
  border-width: 2px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.tb-navbar-navlink1 {
  color: var(--dl-color-touchbase-lblue);
}
.tb-navbar-navlink1:hover {
  color: var(--dl-color-touchbase-lgreen);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-touchbase-lgreen);
  border-width: 2px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}













@media(max-width: 767px) {
  .tb-navbar-navbar {
    padding-right: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 479px) {
  .tb-navbar-navbar {
    padding-right: var(--dl-space-space-doubleunit);
  }
}
