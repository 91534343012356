.education-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.education-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.education-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.education-image1 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.education-image2 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.education-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.education-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.education-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.education-title {
  color: var(--dl-color-feelers-dark);
}
.education-text {
  color: var(--dl-color-feelers-dark);
  margin-top: var(--dl-space-space-tenunits);
}
.education-right-container {
  width: 60%;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.education-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.education-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.education-left-container1 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.education-row2-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.education-left-container2 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.education-row3-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.education-col1-container {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.education-col2-container {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.education-navlink {
  display: contents;
}
.education-ads-container {
  color: var(--dl-color-feelers-dark);
  width: 50%;
  display: flex;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  text-decoration: none;
}
.education-ads-container:hover {
  color: var(--dl-color-feelers-accent);
  width: 55%;
}
.education-voices-container {
  color: var(--dl-color-feelers-dark);
  width: 65%;
  display: flex;
  opacity: 0;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.education-voices-container:hover {
  color: var(--dl-color-feelers-accent);
  width: 70%;
}
.education-distance-container {
  color: var(--dl-color-feelers-dark);
  width: 58%;
  display: flex;
  opacity: 0;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.education-distance-container:hover {
  color: var(--dl-color-feelers-accent);
  width: 63%;
}
.education-office-container {
  color: var(--dl-color-feelers-dark);
  width: 70%;
  display: flex;
  opacity: 0;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.education-office-container:hover {
  color: var(--dl-color-feelers-accent);
  width: 75%;
}
.education-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media (max-width: 991px) {
  .education-body-container {
    flex-direction: column;
  }
  .education-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .education-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 767px) {
  .education-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .education-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .education-row1-container {
    flex-direction: column;
  }
  .education-left-container1 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .education-row2-container {
    flex-direction: column;
  }
  .education-left-container2 {
    width: 100%;
  }
  .education-row3-container {
    flex-direction: column;
  }
  .education-col1-container {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .education-col2-container {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
}
