.totapo-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.totapo-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.totapo-body {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.totapo-row1 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.totapo-img1 {
  width: 15%;
  height: fit-content;
  margin-top: 6%;
  object-fit: contain;
}
.totapo-img2 {
  width: 52%;
  object-fit: contain;
}
.totapo-img3 {
  width: 15%;
  height: fit-content;
  align-self: flex-end;
  object-fit: contain;
}
.totapo-row2 {
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.totapo-left {
  width: 58%;
  display: flex;
  flex-direction: column;
}
.totapo-text {
  width: 75%;
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.totapo-link {
  color: var(--dl-color-gray-white);
  width: 25%;
  padding: var(--dl-space-space-unit);
  font-size: 18px;
  text-align: center;
  transition: 0.3s;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 40px;
  text-transform: none;
  text-decoration: none;
  background-color: var(--dl-color-totapo-orange);
}
.totapo-link:hover {
  background-color: var(--dl-color-totapo-yellow);
}
.totapo-image {
  width: 38%;
  align-self: flex-end;
  object-fit: contain;
}
.totapo-right {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-text01 {
  color: var(--dl-color-totapo-orange);
  font-size: 20px;
  font-style: normal;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.totapo-text02 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-text03 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-text04 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-text05 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.totapo-text06 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-style: italic;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.totapo-text07 {
  color: var(--dl-color-totapo-yellow);
  font-size: 20px;
  font-style: normal;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.totapo-text08 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.totapo-text09 {
  color: var(--dl-color-totapo-grey);
  font-size: 14px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-row3 {
  width: 100%;
  display: flex;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.totapo-text10 {
  color: var(--dl-color-totapo-orange);
  width: 75%;
  font-size: 18px;
  align-self: center;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-img {
  width: 20%;
}
.totapo-image1 {
  width: 110%;
  object-fit: contain;
}
.totapo-row4 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-end;
}
.totapo-text11 {
  color: var(--dl-color-totapo-yellow);
  width: 55%;
  font-size: 18px;
  text-align: right;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-row5 {
  display: flex;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.totapo-left1 {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-img1 {
  width: 50%;
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: flex-end;
}
.totapo-image2 {
  width: 150%;
  object-fit: contain;
}
.totapo-text12 {
  color: var(--dl-color-totapo-grey);
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.totapo-image3 {
  width: 40%;
  align-self: center;
  margin-top: var(--dl-space-space-tenunits);
  object-fit: contain;
}
.totapo-centre {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-text13 {
  color: var(--dl-color-totapo-grey);
  font-size: 18px;
  margin-top: var(--dl-space-space-fiveunits);
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 230px;
  text-transform: none;
  text-decoration: none;
}
.totapo-text14 {
  color: var(--dl-color-totapo-grey);
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-right1 {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-image4 {
  width: 38%;
  align-self: center;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-unit);
}
.totapo-text15 {
  color: var(--dl-color-totapo-grey);
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-tenunits);
  text-transform: none;
  text-decoration: none;
}
.totapo-image5 {
  width: 55%;
  align-self: center;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-unit);
}
.totapo-text16 {
  color: var(--dl-color-totapo-grey);
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-row6 {
  display: flex;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.totapo-left2 {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-text17 {
  color: var(--dl-color-totapo-orange);
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-centre1 {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-image6 {
  width: 90%;
  align-self: center;
  margin-top: 200px;
  object-fit: contain;
}
.totapo-right2 {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.totapo-img2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.totapo-image7 {
  width: 125%;
  object-fit: contain;
}
.totapo-text18 {
  color: var(--dl-color-totapo-yellow);
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-row7 {
  width: 100%;
  display: flex;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.totapo-text19 {
  color: var(--dl-color-totapo-grey);
  width: 65%;
  font-size: 18px;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-text20 {
  width: 55%;
}
.totapo-text23 {
  width: 55%;
}
.totapo-text25 {
  width: 55%;
}
.totapo-text27 {
  width: 55%;
}
.totapo-text29 {
  width: 55%;
}
.totapo-text32 {
  width: 55%;
}
.totapo-text35 {
  width: 55%;
}
.totapo-row8 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.totapo-text36 {
  color: var(--dl-color-totapo-orange);
  font-size: 18px;
  text-align: end;
  font-family: Arial;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.totapo-text37 {
  color: var(--dl-color-totapo-orange);
  font-size: 18px;
  text-align: end;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.totapo-link1 {
  color: var(--dl-color-gray-white);
  width: 15%;
  padding: var(--dl-space-space-unit);
  font-size: 18px;
  text-align: center;
  transition: 0.3s;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 40px;
  text-transform: none;
  text-decoration: none;
  background-color: var(--dl-color-totapo-orange);
}
.totapo-link1:hover {
  background-color: var(--dl-color-totapo-yellow);
}
.totapo-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 1200px) {
  .totapo-text21 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text22 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text24 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text26 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text28 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text30 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text31 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text33 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
  .totapo-text34 {
    color: var(--dl-color-totapo-yellow);
    font-size: 18px;
    font-family: Arial;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .totapo-row1 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .totapo-img2 {
    width: 90%;
    align-self: center;
  }
  .totapo-row2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .totapo-left {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .totapo-text {
    width: 100%;
  }
  .totapo-link {
    align-self: center;
  }
  .totapo-right {
    width: 100%;
  }
  .totapo-row3 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .totapo-img {
    align-self: center;
  }
  .totapo-row4 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    justify-content: center;
  }
  .totapo-text11 {
    width: 75%;
  }
  .totapo-row5 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .totapo-left1 {
    width: 100%;
  }
  .totapo-text12 {
    align-self: center;
    text-align: center;
  }
  .totapo-centre {
    width: 100%;
  }
  .totapo-text13 {
    align-self: center;
    text-align: center;
  }
  .totapo-text14 {
    align-self: center;
    text-align: center;
  }
  .totapo-right1 {
    width: 100%;
  }
  .totapo-image4 {
    margin-top: var(--dl-space-space-fiveunits);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .totapo-text15 {
    align-self: center;
    text-align: center;
  }
  .totapo-text16 {
    align-self: center;
    text-align: center;
  }
  .totapo-row6 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .totapo-left2 {
    width: 100%;
  }
  .totapo-centre1 {
    width: 100%;
  }
  .totapo-image6 {
    width: 30%;
    align-self: flex-end;
    margin-top: var(--dl-space-space-unit);
  }
  .totapo-right2 {
    width: 100%;
  }
  .totapo-image7 {
    width: 100%;
  }
  .totapo-row7 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .totapo-text19 {
    width: 100%;
  }
  .totapo-row8 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .totapo-text36 {
    align-self: center;
  }
  .totapo-text37 {
    align-self: center;
  }
  .totapo-link1 {
    width: 25%;
    align-self: center;
    text-align: center;
  }
}
@media(max-width: 479px) {
  .totapo-text36 {
    align-self: center;
    text-align: center;
  }
}
