.plot-plot {
  fill: var(--dl-color-feelers-dark);
  color: var(--dl-color-feelers-dark);
  display: flex;
  transition: 0.3s;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
}
.plot-plot:hover {
  fill: var(--dl-color-feelers-accent);
  color: var(--dl-color-feelers-accent);
}
.plot-icon {
  fill: inherit;
  width: 10px;
  height: 10px;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-halfunit);
}
.plot-text {
  color: inherit;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
