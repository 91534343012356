.tb02-intro-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tb02-intro-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.tb02-intro-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.tb02-intro-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  margin-top: 100px;
  padding-left: 200px;
  padding-right: 200px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.tb02-intro-t1 {
  color: var(--dl-color-touchbase-dblue);
  padding-left: 50px;
}
.tb02-intro-t2 {
  color: var(--dl-color-touchbase-lblue);
  padding-left: 60px;
  margin-bottom: var(--dl-space-space-tenunits);
}
.tb02-intro-t3 {
  color: var(--dl-color-touchbase-dblue);
  width: 80%;
  align-self: flex-end;
}
.tb02-intro-t4 {
  color: var(--dl-color-touchbase-dgreen);
  width: 35%;
  margin-top: var(--dl-space-space-fiveunits);
  margin-left: 250px;
}
.tb02-intro-t5 {
  color: var(--dl-color-touchbase-dblue);
  width: 60%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-halfunit);
}
.tb02-intro-t6 {
  color: var(--dl-color-touchbase-dgreen);
  width: 20%;
  margin-top: var(--dl-space-space-fiveunits);
  margin-left: var(--dl-space-space-fiveunits);
}
.tb02-intro-t7 {
  color: var(--dl-color-touchbase-dblue);
  width: 90%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-halfunit);
}
.tb02-intro-t8 {
  color: var(--dl-color-touchbase-dgreen);
  width: 45%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-tenunits);
}
.tb02-intro-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media (max-width: 991px) {
  .tb02-intro-body-container {
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
    flex-direction: column;
  }
  .tb02-intro-t1 {
    padding-left: 0px;
  }
  .tb02-intro-t2 {
    padding-left: 0px;
  }
  .tb02-intro-t3 {
    width: 100%;
  }
  .tb02-intro-t4 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .tb02-intro-t5 {
    width: 100%;
  }
  .tb02-intro-t6 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .tb02-intro-t7 {
    width: 100%;
  }
  .tb02-intro-t8 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 767px) {
  .tb02-intro-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
@media (max-width: 479px) {
  .tb02-intro-t1 {
    font-size: 40px;
  }
  .tb02-intro-t2 {
    font-size: 30px;
  }
}
