.caterfeelers-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.caterfeelers-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
.caterfeelers-image {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
.caterfeelers-image01 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.caterfeelers-image02 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
.caterfeelers-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.caterfeelers-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.caterfeelers-left-container {
  width: 40%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.caterfeelers-title {
  color: var(--dl-color-feelers-dark);
}
.caterfeelers-img-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-image03 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.caterfeelers-col2 {
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.caterfeelers-image04 {
  width: 32%;
  object-fit: contain;
}
.caterfeelers-image05 {
  width: 32%;
  object-fit: contain;
}
.caterfeelers-image06 {
  width: 32%;
  object-fit: contain;
}
.caterfeelers-col21 {
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.caterfeelers-image07 {
  width: 47%;
  object-fit: contain;
}
.caterfeelers-image08 {
  width: 47%;
  object-fit: contain;
}
.caterfeelers-col22 {
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.caterfeelers-image09 {
  width: 47%;
  object-fit: contain;
}
.caterfeelers-image10 {
  width: 47%;
  object-fit: contain;
}
.caterfeelers-right-container {
  width: 60%;
  display: flex;
  padding-top: 120px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
.caterfeelers-header-container {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.caterfeelers-text {
  color: var(--dl-color-feelers-dark);
}
.caterfeelers-body-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.caterfeelers-row1-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.caterfeelers-left-container1 {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-text1 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
}
.caterfeelers-row2-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.caterfeelers-link {
  color: var(--dl-color-feelers-dark);
  height: fit-content;
  font-style: italic;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-fiveunits);
  text-transform: uppercase;
}
.caterfeelers-link:hover {
  color: var(--dl-color-feelers-accent);
}
.caterfeelers-col2-container {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-text4 {
  color: var(--dl-color-feelers-dark);
}
.caterfeelers-row3-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.caterfeelers-col1-container {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.caterfeelers-col2-container1 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-text5 {
  color: var(--dl-color-feelers-dark);
  width: 100%;
}
.caterfeelers-row3-container1 {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.caterfeelers-col1-container1 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.caterfeelers-col2-container2 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-text6 {
  color: var(--dl-color-feelers-dark);
}
.caterfeelers-row4-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.caterfeelers-col1-container2 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.caterfeelers-col2-container3 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-text7 {
  color: var(--dl-color-feelers-dark);
}
.caterfeelers-row5-container {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.caterfeelers-col1-container3 {
  color: var(--dl-color-feelers-dark);
  width: 27%;
  display: flex;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.caterfeelers-col2-container4 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.caterfeelers-text8 {
  color: var(--dl-color-feelers-dark);
}
.caterfeelers-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}
@media(max-width: 991px) {
  .caterfeelers-body-container {
    flex-direction: column;
  }
  .caterfeelers-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .caterfeelers-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .caterfeelers-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .caterfeelers-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .caterfeelers-row1-container {
    flex-direction: column;
  }
  .caterfeelers-text1 {
    width: 100%;
  }
  .caterfeelers-row2-container {
    flex-direction: column;
  }
  .caterfeelers-col2-container {
    width: 100%;
  }
  .caterfeelers-text4 {
    width: 100%;
  }
  .caterfeelers-row3-container {
    flex-direction: column;
  }
  .caterfeelers-col1-container {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-col2-container1 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-row3-container1 {
    flex-direction: column;
  }
  .caterfeelers-col1-container1 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-col2-container2 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-row4-container {
    flex-direction: column;
  }
  .caterfeelers-col1-container2 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-col2-container3 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-row5-container {
    flex-direction: column;
  }
  .caterfeelers-col1-container3 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .caterfeelers-col2-container4 {
    width: 100%;
    padding-left: 0;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
}
